// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Button, Tooltip } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import ExamActivityComponentController, {
  Props,
} from "./ExamActivityComponentController";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { infoIcon, redPenIcon, redTrashIcon } from "./assets"; 
import AddObjectiveQuestionModal from "./AddObjectiveQuestionModal.web";
import AddSubjectiveQuestionModal from "./AddSubjectiveQuestionModal.web";
import DeleteConfirmDialogWeb from "./DeleteConfirmDialog.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    examActivityDetailBox: {
      minWidth: "500px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "97%",
      },
    },
    questionCard: {
      width: "100%",
      border: "1px solid #f1f1f1",
      borderRadius: "6px",
      [theme.breakpoints.down("xs")]: {
        width: "84%",
      },
      marginBottom: "25px",
    },
    questionContainer: {
      gap: "20px",
      marginTop: "20px",
    },
    pagePadding: {
      padding: "15px",
    },
    aboutText: {
      marginTop: "5px",
    },
    examTitle: {
      display: "flex",
      gap: "7px",
      alignItems: "center",
      textTransform: "capitalize",
    },
    explanation: {
      padding: "15px 20px",
      borderTop: "1px solid #f1f1f1",
      borderBottom: "1px solid #f1f1f1",
    },
    questionDeleteBtn: {
      maxWidth: "32px",
      minWidth: "32px",
      border: "1px solid #ED1111",
      borderRadius: "6px",
      height: "32px",
    },
    questionEditBtn: {
      minWidth: "32px",
      height: "32px",
      maxWidth: "32px",
      borderRadius: "6px",
      border: "1px solid #0D46BC",
    },
    bottomBorder: {
      borderBottom: "1px solid #f1f1f1",
    },
  });

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: "#EEF8FF",
  },
  tooltip: {
    backgroundColor: '#EEF8FF',
    color: '#009DFF',
    minWidth: '200px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #EEF8FF',
    borderRadius: '10px',
    padding: "9 12 9 12",
    textTransform: 'capitalize',
  },
}))(Tooltip);

// Customizable Area End

// Customizable Area Start
export class ExamActivityComponent extends ExamActivityComponentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }

  toolTipBox() {
    const { detail, classes } = this.props;
    return (
      <Box>
        <Typography className={classes.fontText12}> Assessment Type: <b>{detail?.gradingComponent}</b></Typography>
        <Typography className={classes.fontText12}> Evaluation Type: <b>{detail?.evaluationType}</b></Typography>
        {
          detail?.objectiveType &&
          <Typography className={classes.fontText12}>Objective Type: <b>{detail?.objectiveType}</b></Typography>
        }
        {
          detail?.duration &&
          <Typography className={classes.fontText12}> Duration: <b>{detail?.duration} mins</b></Typography>
        }
        {
          detail?.maxScore &&
          <Typography className={classes.fontText12}> Max Score: <b>{detail?.maxScore}</b></Typography>
        }
        {
          detail?.showCorrectAnswer &&
          <Typography className={classes.fontText12}> Show Answers to Students: <b>{detail?.showCorrectAnswer ? 'Yes' : 'No'}</b></Typography>
        }
      </Box>
    );
  }
  getImageExam = (isMyCoursesPath:boolean , redpenFun:(dataId:number|string) => void , redTraFun:(dataId:number|string) => void , dataId:number|string , testEdit:string ,testDelete:string) => {
  return  !isMyCoursesPath && this.state.userRole && (
      <>
      <img src={redPenIcon} style={{border:"1px solid blue", borderRadius:"5px", padding:"7px" }} alt='redpen' 
     data-test-id={testEdit} onClick={() => redpenFun(dataId)}
    />                         
    <img src={redTrashIcon} alt="" style={{border:"1px solid red", borderRadius:"5px",padding:"7px"}} data-test-id={testDelete}  onClick={() =>redTraFun(dataId)}/>
   </>)
  }
  myCourseExam = (isMyCoursesPath:boolean,btnStyle:object , btnOnClick:() => void, testId:string) => {    
    return !isMyCoursesPath && this.state.userRole ?
     (
       <CustomButton
         btnText="Add Another Question"
         btnStyle={btnStyle}
         buttonId={testId}
         buttonVariant={"outlined"}
         isDisabled={false}
         type={"button"}
         handleButtonClick={btnOnClick}
       />)
       : null
   }
   renderObjectiveTypeUI=(classes:any,questionList:any,detail:any,isFromRegistrar:any)=>{
    return (
      <Box
      className={`${classes.questionContainer}`}
      display={"flex"}
      flexDirection={"column"}
    >
      {
        questionList && questionList.length > 0 ?
          <Box>
            {questionList?.map((questionObj: any, index:any) => (
              <Box
                className={`${classes.questionCard}`}
                width={"94%"}
                key={questionObj?.id}
                display={"flex"}
                flexDirection={"column"}
                borderRadius={"6px"}
              >
                <Box p={"20px 20px 0 20px"}>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      <Typography className={`${classes.textCommonColor}`}>
                        Question {index + 1}
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Typography
                      className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                      dangerouslySetInnerHTML={{
                        __html: questionObj?.question,
                      }}
                    />
                  </Box>

                  {detail.objectiveType === "written" && (
                    <Box
                      display={"flex"}
                      gridGap={"4px"}
                      flexDirection={"column"}
                      margin={"12px 0"}
                    >
                      {
                        <Box
                          display={"flex"}
                          className={`${classes.textCommonColor}`}
                          flexDirection={"column"}
                        >
                          <Box marginRight={"5px"}>
                            <Typography className={`${classes.fontBold600}`}>
                              Correct Answer:
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              className={`${classes.primaryColor4} ${classes.fontBold500}`}
                            >
                              {questionObj?.correctAnswer}
                            </Typography>
                          </Box>
                        </Box>
                      }
                    </Box>
                  )}

                  {detail.objectiveType === "selection" && (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      gridGap={"4px"}
                      margin={"12px 0"}
                    >
                      {questionObj.options.map((option: any) => (
                        <Box
                          className={
                            option?.inputValue === questionObj?.correctAnswer
                              ? `${classes.primaryColor4}`
                              : `${classes.textCommonColor}`
                          }
                          key={option?.id}
                          display={"flex"}
                        >
                          <Box mr={"5px"}>
                            <Typography className={`${classes.fontBold600}`}>
                              {option?.optionLetter}:
                            </Typography>
                          </Box>
                          <Box>
                            <Typography className={`${classes.fontBold500}`}>
                              {option?.inputValue}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>

                <Box
                  className={`${classes.explanation} ${classes.textCommonColor}`}
                >
                  <Box>
                    <Typography className={`${classes.fontBold600}`}>
                      Explanation:
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: "-2px" }}>
                    <Typography
                      className={`${classes.fontBold500}`}
                      dangerouslySetInnerHTML={{
                        __html: questionObj?.explanation,
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  p={"20px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Box display={"flex"}>
                    <Box marginRight={"5px"} component={"span"}>
                      <Typography className={`${classes.textCommonColor}`}>
                        Marks:
                      </Typography>
                    </Box>
                    <Box component={"span"}>
                      <Typography className={`${classes.textPrimaryColor}`}>
                        {questionObj?.marks}
                      </Typography>
                    </Box>
                  </Box>
                  {(!isFromRegistrar && this.props?.isActivityWrite )&& <Box display={"flex"} gridGap={"10px"}>
                    {this.getImageExam(this.state.isMyCoursesPath, (dataId) => this.handleEditObjectiveQuestion(questionObj.id),  (dataId) =>this.handleDeleteExamObjectiveQuestion(questionObj.id) , questionObj.id , 'exam-mcq-question-edit-73' ,'exam-mcq-question-delete-73')}
                  </Box>}
                </Box>
              </Box>
            ))}
{(!isFromRegistrar && this.props?.isActivityWrite) && <Box className={classes.footerButtonsBox}>
              {this.myCourseExam(this.state.isMyCoursesPath ,addQuestionButton ,this.handleAddObjectiveQuestion , 'exam-add-another-objquestion-button')}
              </Box>
              }                 
           
          </Box>
          :
          <CustomEmptyResultComponent
            message="No question added for this Exam"
            showActionButton={(!isFromRegistrar && this.props?.isActivityWrite)&& this.state.userRole}
            buttonText="Add Question"
            handleButtonClick={this.handleAddObjectiveQuestion}
          />
      }
    </Box>
    )
   }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, detail, questionList, isFromRegistrar } = this.props;
    const {
      editQuestion,
      openObjectiveQuestionModal,
      openSubjectiveQuestionModal,
      selectedQuestionDetail,
      openDeleteQuestionConfirmModal,
    } = this.state;

    return (
      <Box className={classes.examActivityDetailBox}>
        <Grid
          className={`${classes.pagePadding}`}
          data-test-id={detail.typeOfActivity + "Activity"}
          container
          direction="column"
        >
          <Grid item className={classes.titleGrid} xs={12}>
            <Typography
              component={"span"}
              className={`${classes.textPrimaryColor} ${classes.fontText20} ${classes.fontBold600} ${classes.examTitle}`}
            >
              {`${detail?.activityTitle}`}
              <HtmlTooltip
                placement="right-start"
                title={
                  <React.Fragment>
                    {this.toolTipBox()}
                  </React.Fragment>
                }
              >
                <img src={infoIcon} alt="info" />
              </HtmlTooltip>
            </Typography>
          </Grid>

          {detail?.instruction && (
            <Grid className={classes.detailGrid} item xs={12}>
              <Typography
                className={`${classes.textCommonColor} ${classes.fontText16} ${classes.fontBold500}`}
                component={"p"}
                dangerouslySetInnerHTML={{ __html: detail.instruction }}
              ></Typography>
            </Grid>
          )}

          {detail.evaluationType === "objective" && (
            this.renderObjectiveTypeUI(classes,questionList,detail,isFromRegistrar)
          )}

          {detail.evaluationType === "subjective" && (
            <Box
              className={`${classes.questionContainer}`}
              display={"flex"}
              flexDirection={"column"}
            >
              {
                questionList && questionList.length > 0 ?
                  <Box>
                    {questionList?.map((examSubjectiveQuestion: any, index) => (
                      <Box
                        width={"94%"}
                        className={`${classes.questionCard}`}
                        key={examSubjectiveQuestion?.id}
                        borderRadius={"6px"}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <Box className={classes.bottomBorder} p={"20px"}>
                          <Box
                            width={"100%"}
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Box>
                              <Typography className={`${classes.textCommonColor}`}>
                                Question {index + 1}
                              </Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              className={`${classes.fontBold600} ${classes.textPrimaryColor}`}
                              dangerouslySetInnerHTML={{
                                __html: examSubjectiveQuestion?.question,
                              }}
                            />
                          </Box>
                        </Box>

                        <Box
                          p={"20px"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <Box display={"flex"}  >
                            <Box marginRight={"5px"} component={"span"}>
                              <Typography className={`${classes.textCommonColor}`}>
                                Marks:
                              </Typography>
                            </Box>
                            <Box component={"span"}>
                              <Typography className={`${classes.textPrimaryColor}`}>
                                {examSubjectiveQuestion?.marks}
                              </Typography>
                            </Box>
                            <Box
                              marginLeft={"15px"}
                              marginRight={"5px"}
                              component={"span"}
                            >
                              <Typography className={`${classes.textCommonColor}`}>
                                Word Limit:
                              </Typography>
                            </Box>
                            <Box component={"span"}>
                              <Typography className={`${classes.textPrimaryColor}`}>
                                {examSubjectiveQuestion?.wordLimit}
                              </Typography>
                            </Box>
                            
                          </Box>
                          {(!isFromRegistrar &&  this.props?.isActivityWrite ) && <Box display={"flex"} gridGap={"10px"}>
                            {this.getImageExam(this.state.isMyCoursesPath, (dataId) => this.handleEditSubjectiveQuestion(examSubjectiveQuestion.id),  (dataId) =>this.handleDeleteExamObjectiveQuestion(examSubjectiveQuestion.id) , examSubjectiveQuestion.id , 'subjective-exam-question-edit-73' ,'subjective-exam-question-delete-73')}
                          </Box>}
                        </Box>                       
                      </Box>
                    ))}
                    {(!isFromRegistrar && this.props?.isActivityWrite )&& <Box className={classes.footerButtonsBox}>
                      {this.myCourseExam(this.state.isMyCoursesPath ,addQuestionButton ,this.handleAddSubjectiveQuestion , 'add-another-exam-subjective-question-button')}
                      </Box>
                      }
                  </Box>
                  :
                  <CustomEmptyResultComponent
                    message="No question added for this Exam"
                    showActionButton={(!isFromRegistrar && this.props?.isActivityWrite) && this.state.userRole}
                    buttonText="Add Question"
                    handleButtonClick={this.handleAddSubjectiveQuestion}
                  />
              }
            </Box>
          )}
          
        </Grid>
        
        {openObjectiveQuestionModal && (
          <AddObjectiveQuestionModal
            classes={classes}
            open={openObjectiveQuestionModal}
            isEdit={editQuestion}
            onCancel={this.handleCloseObjectiveQuestionModal}
            onConfirm={this.handleCloseObjectiveQuestionModal}
            handleAddQuestion={(data: any) => this.handleExamAddQuestion(data)}
            handleUpdateQuestion={(data: any) =>
              this.handleExamUpdateQuestion(data)
            }
            activityId={detail?.activityId}
            selectedQuestionData={selectedQuestionDetail}
            handleDeleteQuestion={() =>
              this.handleDeleteExamObjectiveQuestion(selectedQuestionDetail?.id)
            }
          />
        )} 

        {openSubjectiveQuestionModal && (
          <AddSubjectiveQuestionModal
            classes={classes}
            explanation={this.checkObjectiveType()}
            open={openSubjectiveQuestionModal}
            isEdit={editQuestion}
            onCancel={this.handleCloseSubjectiveQuestionModal}
            onConfirm={this.handleCloseSubjectiveQuestionModal}
            handleAddSubjectiveQuestion={(data: any) =>
              this.handleExamAddQuestion(data)
            }
            handleUpdateSubjectiveQuestion={(data: any) =>
              this.handleExamUpdateQuestion(data)
            }
            activityId={detail?.activityId}
            selectedSubjectiveQuestionData={selectedQuestionDetail}
            handleDeleteSubjectiveQuestion={() =>
              this.handleDeleteExamObjectiveQuestion(selectedQuestionDetail?.id)
            }
            forManual={false}
          />
        )}

        <DeleteConfirmDialogWeb
          open={openDeleteQuestionConfirmModal}
          headingTitle="Question"
          itemTitle={"this question"}
          onCancel={() => this.handleCloseExamDeleteConfirmModal()}
          onConfirm={() => this.handleConfirmExamDeleteQuestion()}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const addQuestionButton = {
  height: "50px",
  width: "max-content",
  borderRadius: "12px",
  padding: "15px 20px",
  fontWeight: 500,
  fontSize: "18px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(ExamActivityComponent);
// Customizable Area End
